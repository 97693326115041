.navbar-brand img {
    width: 120px;
}

table.table.table-borderless {
    th, td {
        border: 0px none !important;
    }
}

.custom-checkbox, .custom-radio {
    input,
    label {
        cursor: pointer;
    }

    label {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.main {
    .container-fluid {
        @media screen and (max-width: 767px) {
            padding: 0px 3px;
        }
    }
}
