@import '~obiado/assets/scss/variables';

body {
    .vuecal--rounded-theme {
        height: 340px;

        .vuecal__cell {
            height: 40px;

            .vuecal__cell-events-count {
                display: none !important;
            }

            .vuecal__cell-content {
                position: relative;
                overflow: hidden;
                cursor: pointer;
                width: 36px;
                height: 36px;
                -webkit-transition: 0.25s ease border;
                -moz-transition: 0.25s ease border;
                -ms-transition: 0.25s ease border;
                -o-transition: 0.25s ease border;
                transition: 0.25s ease border;

                &:hover {
                    border: solid 1px $primary !important;
                }

                .calendar-event {
                    position: absolute;
                    z-index: 0;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;

                    &.event-free-day {
                        background-color: $gray-300;
                    }

                    &.event-selection {
                        z-index: 1;
                        background-color: lighten($primary, 20);
                    }

                    &.event-presence {
                        background-color: $success;
                    }

                    &.event-absence {
                        background-color: $danger;
                    }
                }

                span {
                    z-index: 10;
                    background-color: transparent;
                }
            }
        }
    }
}
