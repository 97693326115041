.modal {
    .modal-content {
        border: 0px none !important;

        .modal-header {
            button.close {
                opacity: 1 !important;

                &:hover {
                    opacity: 0.6 !important;
                }
            }

            &.bg-primary,
            &.bg-secondary,
            &.bg-success,
            &.bg-warning,
            &.bg-danger,
            &.bg-info,
            &.bg-light,
            &.bg-dark{
                button.close {
                    color: inherit;
                }
            }
        }
    }
}
