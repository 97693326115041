














$fa-font-path: '~@fortawesome/fontawesome-free/fonts/';
@import '~@fortawesome/fontawesome-free/css/all.min.css';

@import '~bootstrap-vue/dist/bootstrap-vue.min.css';

@import 'assets/scss/app';
